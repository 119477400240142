<template>
  <div class="container mt-5">
    <h1 class="text-center mb-4">プライバシーポリシー</h1>
    <div class="policy-content">
      <p>本プライバシーポリシーは、当サイトが収集する情報の種類、使用方法、および情報の保護方法について説明します。</p>
      
      <h2>1. 収集する情報</h2>
      <p>当サイトでは、ユーザーの個人情報を収集していません。</p>
      <p>そのため、当サイトは	欧州データ保護規制(GDPR)に準拠しています。</p>
      
      <h2>2. 情報の使用</h2>
      <p>個人情報の収集を行っていないため、個人情報を使用することはありません。</p>
      
      <h2>3. 情報の保護</h2>
      <p>当サイトでは、個人情報の収集や保管を行っていないため、個人情報の保護に関する対策は必要ありません。ただし、サイトのセキュリティを維持するため、一般的な技術的なセキュリティ対策を講じています。</p>
      
      <h2>4. クッキーの使用</h2>
      <p>当サイトではクッキーを使用しておらず、ユーザーの行動データや個人情報を追跡することはありません。</p>
      
      <h2>5. 第三者への提供</h2>
      <p>当サイトでは、個人情報を収集していないため、第三者に個人情報を提供することはありません。</p>
      
      <h2>6. プライバシーポリシーの変更</h2>
      <p>本プライバシーポリシーは、予告なく変更されることがあります。変更があった場合は、当ページにてお知らせします。</p>
      
      <h2>7. お問い合わせ</h2>
      <p>プライバシーポリシーに関するご質問やご意見がありましたら、お問い合わせページよりご連絡ください。</p>
    </div>
  </div>
</template>

<script>
export default {
name: 'PrivacyPolicy'
};
</script>

<style scoped>
.container {
max-width: 800px;
}
.policy-content {
text-align: left;
}
h1 {
margin-bottom: 1rem;
}
h2 {
margin-top: 1.5rem;
margin-bottom: 0.5rem;
}
ul {
margin-left: 1.5rem;
}
</style>